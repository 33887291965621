.ui-inputtext {
  padding: 6px 12px !important;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

input {
  border-radius: 0px;
  border: none !important;
  width: 100%;
  border: 0 !important;
  outline: 0 !important;
}
input:focus {
  outline: none !important;
  width: 100%;
}

.form-control {
  border-radius: 1px;
  border: 1px solid #d8dbe0 !important;
  width: 100%;
  outline: 0 !important;
}
